/* @import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap"); */

body {
    background-color: rgb(250, 250, 250);
}

body, input, textarea, button {
    font-family: 'PT Sans', sans-serif;
}

.info__container {
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 15%;
    padding-right: 15%;
}

.form {
    height: 150vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 25%;
}

.form > h1 {
    margin-bottom: 30px;
    font-size: 50px;
}

.form > input, textarea {
    padding: 20px;
    border-radius: 3px;
    /* box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.137); */
    margin-bottom: 20px;
    border: 1px solid lightgray;
    background: #fff;
    font-size: 16px;
    color: rgb(0, 0, 32);
    outline: none;
}

.form > input:focus, textarea:focus {
    border: 1px solid #2a2a2a;
}

::placeholder {
    font-size: 14px;
    font-weight: middle;
    color: #b0b0b0;
}

.form > textarea {
    height: 150px;
    min-height: 200px;
}

.form > label {
    padding-bottom: 10px;
    color: rgb(0, 0, 32);
    font-weight: bold;
}

.form > button {
    padding: 20px;
    border: none;
    background-color: #2a2a2a;
    font-weight: 500;
    border-radius: 4px;
    font-size: 20px;
    color: #fff;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    margin-top: 10px;
    padding-bottom: 20px;
}

.form > button:hover {
    background-color: rgb(2, 2, 110);
}

@media screen and (max-width: 991px) {
    .form {
        height: 170vh;
        padding: 15%;
    }
}

@media screen and (max-width: 768px) {
    .form {
        height: 190vh;
        padding: 7%;
    }
}
